import Marquee from "react-fast-marquee"

import dutch from './resources/images/c-us_dutch.png'
import english from './resources/images/c-us_english.png'
import german from './resources/images/c-us_german.png'
import french from './resources/images/c-us_french.png'

const  App = () => {
  const img = [german, english, french, dutch]
  return (
    <Marquee>
      <div className={'item'}><img src={ img[0] } alt={'c-us'}/></div>
      <div className={'item'}><img src={ img[1] } alt={'c-us'}/></div>
      <div className={'item'}><img src={ img[2] } alt={'c-us'}/></div>
      <div className={'item'}><img src={ img[3] } alt={'c-us'}/></div>
    </Marquee>

  )
}

export default App
